<template>
  <!-- 结算管理 - 开票管理 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">预付款列表(财务)</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>

              <el-select
                size="small"
                v-model="retrievalData.compId"
                remote
                :remote-method="getCompanySearchList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanySearchList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="付款日期" class="searchboxItem ci-full">
              <span class="itemLabel">付款日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="retrievalData.applicationDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="需要确认" class="searchboxItem ci-full">
              <span class="itemLabel">需要确认:</span>
              <el-select
                v-model="retrievalData.needConfirm"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="items in needConfirmData"
                  :key="items.value"
                  :label="items.label"
                  :value="items.value"
                >
                </el-option>
              </el-select>
            </div>

            <div class="df">
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="addAdvance('Income')"
                >新增</el-button
              >
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="advanceExport()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                minWidth="140"
              />

              <el-table-column
                label="预付款总额（元）"
                align="left"
                show-overflow-tooltip
                prop="advancechargeTotal"
                minWidth="140"
              />
              <el-table-column
                label="已结算金额（元）"
                align="left"
                show-overflow-tooltip
                prop="settledTotal"
                minWidth="140"
              />
              <el-table-column
                label="余额"
                align="left"
                show-overflow-tooltip
                prop="advancechargeBalance"
                minWidth="100"
              />

              <el-table-column
                label="首次预付款日期"
                align="center"
                show-overflow-tooltip
                width="120"
                ><template slot-scope="scope">{{
                  scope.row.firstAdvancechargeDate | momentDate
                }}</template>
              </el-table-column>
              <el-table-column
                label="预付款次数"
                align="left"
                show-overflow-tooltip
                prop="advancechargeCount"
              />
              <el-table-column
                label="需要确认"
                align="left"
                show-overflow-tooltip
                prop="needConfirm"
              />
              <el-table-column
                label="操作"
                align="center"
                width="300px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="confirmationRecord(scope.row.compId)"
                    >确认记录</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="addAdvance('Income', scope.row.compId)"
                    >新增</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="addAdvance('refund', scope.row.compId)"
                    >退款</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="classDetails(scope.row.compId)"
                    >查看班级详情</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 新增预付款|| 退款 弹框 -->
    <el-dialog
      :title="advanceTitle"
      :visible.sync="advanceDialog"
      width="52%"
      top="10%"
      @close="advanceDialogCancel('advanceForm')"
      ><div class="ovy-a">
        <el-form
          :model="advanceForm"
          :rules="rules"
          ref="advanceForm"
          label-width="80px"
          class="demo-ruleForm basicData"
        >
          <el-form-item label="机构名称" prop="compId">
            <el-select
              size="small"
              v-model="advanceForm.compId"
              remote
              :remote-method="getCompanyList"
              filterable
              clearable
              :disabled="advanceDisabled"
              placeholder="请至少输入两个字搜索"
            >
              <el-option
                v-for="item in CompanyList"
                :key="item.compId"
                :label="item.compName"
                :value="item.compId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="advancePriceTitle" prop="price">
            <el-input
              v-model="advanceForm.price"
              clearable
              :placeholder="advancePricePlaceholder"
               onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="上传附件">
            <div style="display: flex; align-items: center">
              <el-upload
                class="upload-demo upload-btn"
                :action="actionUrl"
                :on-error="handleError"
                :on-success="handleSuccess"
                :on-change="uploadChange"
                multiple
                :limit="3"
                :file-list="fileList"
                :show-file-list="false"
                :auto-upload="false"
              >
                <el-button class="bgc-bv" size="mini">上传附件</el-button>
              </el-upload>
              <span style="color: #f46173"
                >支持上传jpg、png、pdf文件。最多上传3个</span
              >
            </div>
            <div style="display: flex; flex-direction: column">
              <span
                style="color: #409eff; text-align: left"
                v-for="(item, index) in advanceForm.NameList"
                :key="index"
              >
                {{ item.fileName }}
                <a
                  @click="jumpLook(item)"
                  style="color: #409eff; margin: 0 15px"
                  >预览</a
                >
                <a @click="removeExl(index)" style="color: red; margin: 0 15px"
                  >删除</a
                >
              </span>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="advanceDialogCancel('advanceForm')">取 消</el-button>
        <el-button @click="advanceDialogDetermine('advanceForm')" class="bgc-bv" type="primary"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 预览附件 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoading"
      @close="uploadLodClose"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "invoicingManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据集
      retrievalData: {
        // 机构id
        compId: "",
        // 付款日期
        applicationDate: "",
        //有无确认
        needConfirm: "",
      },
      //有无确认
      needConfirmData: [
        {
          value: "10",
          label: "有",
        },
        {
          value: "20",
          label: "无",
        },
      ],
      // 机构名称 - 下拉数据
      CompanySearchList: [],
      // 新增预付款||退款 - loading
      advanceDialog: false,
      // 新增预付款||退款 - 表头
      advanceTitle: "",
      // 新增预付款||退款 - 表单label
      advancePriceTitle: "",
      // 新增预付款||退款 - 表单Placeholder
      advancePricePlaceholder: "",
      // 新增预付款||退款 - 数据
      advanceForm: {
        // 机构id
        compId: "",
        // 金额
        price: "",
        //附件
        NameList: [],
      },
      fileList: [],
      advanceDisabled:false,
       rules: {
        compId: [{ required: true, message: "请选择机构", trigger: "change" }],
        price: [{ required: true, message: "请输入金额", trigger: "blur" }],
      },
      // 新增预付款||退款- 机构下拉数据
      CompanyList: [],
      previewLoading: false,
      ImgSrc: "",
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {},
  methods: {
    // 获取 - 机构名称
    getCompanySearchList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanySearchList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        // 当前页数
        pageNum: pageNum,
        // 每页条数
        pageSize: this.pageSize,
      };
      if (this.retrievalData.compId) {
        params.compId = this.retrievalData.compId;
      }
      if (this.retrievalData.needConfirm) {
        params.needConfirm = this.retrievalData.needConfirm;
      }
      if (this.retrievalData.applicationDate) {
        params.advancechargeTimeFront = this.retrievalData.applicationDate[0];
        params.advancechargeTimeAfter = this.retrievalData.applicationDate[1];
      }
      this.doFetch({
        url: "/biz/advancecharge/advancechargeLogList",
        params,
        pageNum,
      });
    },
    getParams() {
      const params = {};
      if (this.retrievalData.compId) {
        params.compId = this.retrievalData.compId;
      }
      if (this.retrievalData.applicationDate) {
        params.advancechargeTimeFront = this.retrievalData.applicationDate[0];
        params.advancechargeTimeAfter = this.retrievalData.applicationDate[1];
      }
      if (this.retrievalData.needConfirm) {
        params.needConfirm = this.retrievalData.needConfirm;
      }
      return params;
    },
    //导出
    advanceExport() {
      const params = {
        ...this.getParams(),
        identity: "10", //10财务
      };
      this.$post("/biz/advancecharge/exportAdvancechargeLogList", params)
        .then((res) => {
          if (res.status == "0") {
            let list = res.data;
            if (!this.downloadItems.includes(list.taskId)) {
              this.$store.dispatch("pushDownloadItems", list.taskId);
            } else {
              this.$message.warning(
                "[" + list.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    //新增预付款 || 退款
    addAdvance(stu, compId = "") {
      this.advanceDialog = true;
      this.advanceStu = stu;
      this.advanceTitle = stu == "Income" ? "新增预付款" : "新增退款";
      this.advancePriceTitle = stu == "Income" ? "预付款" : "退款";
      this.advancePricePlaceholder =
        stu == "Income" ? "请输入预付款金额" : "请输入退款金额";
      if (compId) {
        this.getCompany(compId);
        this.advanceForm.compId = compId;
        this.advanceDisabled = true;
      } else {
          this.advanceDisabled = false;
      }
    },
    //新增预付款 || 退款  -- 取消
    advanceDialogCancel(advanceForm) {
     this.advanceDialog = false;
      this.$refs[advanceForm].resetFields();
      this.advanceForm = {
        compId: "",
        price: "",
        NameList: [],
      };
      this.fileList = [];
      this.CompanyList = [];
    },
    //新增预付款 || 退款 上传附件
    uploadChange(file, fileList) {
      let that = this;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isJpg = extension === "jpg";
      const isJPG = extension === "JPG";
      const isPng = extension === "png";
      const isPNG = extension === "PNG";
      const isPDF = extension === "PDF";
      const isPdf = extension === "pdf";
      if (!isJpg && !isJPG && !isPng && !isPNG && !isPDF && !isPdf) {
        that.$message.error("只能上传后缀是.jpg或.png或者.pdf的文件");
        return;
      }
      if (size > 3) {
        that.$message.error("文件大小不能超过3M");
        return;
      }
      that.fileList = fileList;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      that
        .$Postformat("/sys/upload", formData)
        .then((result) => {
          that.advanceForm.NameList.push({
            fileName: file.name,
            appendix: result.data.fileKey,
            delayFileUrl: result.data.fileURL,
            delayFileType: extension,
          });
          console.log(fileList);
        })
        .catch(() => {
          setTimeout(() => {
            that.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      // that.$forceUpdate();
    },
    jumpLook(item) {
      let extension = item.fileName.substring(
        item.fileName.lastIndexOf(".") + 1
      );
      this.fileType = extension;
      if (this.fileType == "pdf" || this.fileType == "PDF") {
        this.previewLoading = true;
        this.$nextTick(() => {
          pdf.embed(item.delayFileUrl, "#pdf-cert1");
        });
      } else if (this.fileType == "png" || this.fileType == "jpg") {
        this.previewLoading = true;
        this.ImgSrc = item.delayFileUrl;
      }
    },
    removeExl(index) {
      this.advanceForm.NameList.splice(index, 1);
      this.fileList.splice(index, 1);
      console.log("length", this.advanceForm.NameList);
      this.$forceUpdate();
    },
    //新增预付款 || 退款 - 机构名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 新增预付款 || 退款 - 确定
     advanceDialogDetermine(advanceForm) {
      this.$refs[advanceForm].validate((valid) => {
        if (valid) {
          if (!this.advanceForm.NameList.length) {
            this.$message({
              message: "至少上传一个附件",
              type: "error",
            });
            return false;
          }
          let params = {
            compId: this.advanceForm.compId,
            amountMoney: this.advanceForm.price,
            advancechargeType: this.advanceStu == "Income" ? "10" : "20",
            dtos: this.advanceForm.NameList,
          };
          this.$post("/biz/advancecharge/addAdvancechargeLog", params).then(
            (res) => {
              ;
              if (res.status == "0") {
                this.$message({
                  type: "success",
                  message: "新增成功",
                });
                this.getData(-1);
                this.advanceDialogCancel();
              }
            }
          );
        }
      });
    },

    // 新增预付款 || 退款 单位回显接口
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyList = [res.data];
      });
    },
    //确认记录
    confirmationRecord(compId) {
      this.$router.push({
        path: "/web/financialSettlement/advancePaymentConfirmationRecord",
        query: {
          compId,
        },
      });
    },
    //查看班级详情
    classDetails(compId) {
      /* /web/financialSettlement/advancePaymentClassDetails */
      this.$router.push({
        path: "/web/financialSettlement/advancePaymentClassDetails",
        query: {
          compId,
          identity: "20",
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 0.675) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function (route) {
        if (route.query.refresh == true) {
          this.getData(-1);
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
// .searchBox {
//   .el-icon-arrow-down:before {
//     content: "\e6df" !important;
//   }
// }
.bh /deep/ .el-textarea__inner {
  height: 200px;
}
</style>
<style lang="less" scope>
.upload-btn {
  width: 5rem;
  height: 28px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
/deep/ .el-input-group__append,
/deep/.el-input-group__prepend {
  background-color: transparent;
  /* color: #909399; */
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: none;
  border-radius: 4px;
  padding: 0 20px;
  width: 1px;
  white-space: nowrap;
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 30px;
  }
}
.iconStyle {
  font-size: 2rem;
  color: #6676ff;
  cursor: pointer;
}
</style>
